import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-criterio-table",
  templateUrl: "./criterio-table.component.html",
  styleUrls: ["./criterio-table.component.css"],
})
export class CriterioTableComponent implements OnInit {
  private _criterion: any;
  public get criterion(): any {
    return this._criterion;
  }

  @Input()
  public set criterion(value: any) {
    console.log(value)
    this._criterion = value;
  }

  constructor() {}

  ngOnInit(): void {}
}
