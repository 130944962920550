import { Component, OnInit } from '@angular/core';
import { nssActivityList } from 'src/app/constant/activities.constants';

@Component({
  selector: 'app-nss',
  templateUrl: './nss.component.html',
  styleUrls: ['./nss.component.css']
})
export class NssComponent implements OnInit {

  readonly nssList = nssActivityList;
  constructor() { }

  ngOnInit(): void {
  }

}
