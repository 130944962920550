import { Component, OnInit } from '@angular/core';
import { dvvList } from 'src/app/constant/naac/dvv.constant';
import { feedbackList } from './feedback.constant';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  readonly feedbackList = feedbackList;
  constructor() { }

  ngOnInit(): void {
  }

}
