import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-best-practices",
  templateUrl: "./best-practices.component.html",
  styleUrls: ["./best-practices.component.css"],
})
export class BestPracticesComponent implements OnInit {
  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  public photoURL(url: string) {
    console.log(this._sanitizer.bypassSecurityTrustUrl(url));
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
