import { Component, Input, OnInit } from "@angular/core";
import { URLSanitizerService } from "../../service/urlsanitizer.service";

@Component({
  selector: "app-pdf-embed",
  templateUrl: "./pdf-embed.component.html",
  styleUrls: ["./pdf-embed.component.css"],
})
export class PdfEmbedComponent implements OnInit {
  private _url: string;
  public get url(): string {
    return this._url;
  }
  @Input()
  public set url(value: string) {
    this._url = value;
  }

  private _width = "100";
  public get width() {
    return this._width;
  }
  @Input()
  public set width(value) {
    this._width = value;
  }

  private _height = "100";
  public get height() {
    return this._height;
  }
  @Input()
  public set height(value) {
    this._height = value;
  }

  constructor(public urlSAnitizer: URLSanitizerService) {}

  ngOnInit(): void {}

  /**
   * pdfURL:
   */
  public pdfURL(): string {
    return this.urlSAnitizer.sanitizeURL(this._url);
  }
}
