import { Component, OnInit } from '@angular/core';
import { curriculum } from 'src/app/constant/importantLinks.constant';

@Component({
  selector: 'app-curriculam',
  templateUrl: './curriculam.component.html',
  styleUrls: ['./curriculam.component.css']
})
export class CurriculamComponent implements OnInit {

  readonly curriculum= curriculum;
  constructor() { }

  ngOnInit(): void {
  }

}
