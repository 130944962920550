import { Component, OnInit } from "@angular/core";
import { softSkill } from "src/app/constant/activities.constants";

@Component({
  selector: "app-soft-skill",
  templateUrl: "./soft-skill.component.html",
  styleUrls: ["./soft-skill.component.css"],
})
export class SoftSkillComponent implements OnInit {
  readonly softSkillList = softSkill;
  constructor() {}

  ngOnInit(): void {}
}
