import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-best-practises',
  templateUrl: './iqac-best-practises.component.html',
  styleUrls: ['./iqac-best-practises.component.css']
})
export class IqacBestPractisesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
