import { Component, OnInit } from '@angular/core';
import { mouActivity } from 'src/app/constant/activities.constants';

@Component({
  selector: 'app-mou',
  templateUrl: './mou.component.html',
  styleUrls: ['./mou.component.css']
})
export class MouComponent implements OnInit {

  readonly mouActivity = mouActivity;
  constructor() { }

  ngOnInit(): void {
  }

}
