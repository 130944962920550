import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqcs-composition',
  templateUrl: './iqcs-composition.component.html',
  styleUrls: ['./iqcs-composition.component.css']
})
export class IqcsCompositionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
