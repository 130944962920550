import { Component, OnInit } from '@angular/core';
import { activities } from 'src/app/constant/activities.constants';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  readonly activities = activities;

  constructor() { }

  ngOnInit() {
  }

}
