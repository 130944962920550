export const activities = [
  {
    year: "2023",
    list: [
      {
        displayName: "Activity Report July 2023",
        link: "./assets/documentation/activity/2023/july.pdf",
      },
      {
        displayName: "Activity Report June 2023",
        link: "./assets/documentation/activity/2023/june.pdf",
      },
      {
        displayName: "Activity Report May 2023",
        link: "./assets/documentation/activity/2023/may.pdf",
      },
      {
        displayName: "Activity Report April 2023",
        link: "./assets/documentation/activity/2023/apr.pdf",
      },
      {
        displayName: "Activity Report March 2023",
        link: "./assets/documentation/activity/2023/mar.pdf",
      },
      {
        displayName: "Activity Report February 2023",
        link: "./assets/documentation/activity/2023/feb.pdf",
      },
      {
        displayName: "Activity Report January 2023",
        link: "./assets/documentation/activity/2023/jan.pdf",
      },
    ],
  },
  {
    year: "2022",
    list: [
      {
        displayName: "Activity Report August 2022",
        link: "./assets/documentation/activity/2022/aug.pdf",
      },
      {
        displayName: "Activity Report September 2022",
        link: "./assets/documentation/activity/2022/sept.pdf",
      },
      {
        displayName: "Activity Report October 2022",
        link: "./assets/documentation/activity/2022/oct.pdf",
      },
      {
        displayName: "Activity Report November 2022",
        link: "./assets/documentation/activity/2022/nov.pdf",
      },
      {
        displayName: "Activity Report December 2022",
        link: "./assets/documentation/activity/2022/dec.pdf",
      },
    ],
  },
];

export const nssActivityList = [
  {
    title: "NSS 2017-18",
    link: "./assets/documentation/activity/nss/NSS 2017-18.pdf",
  },
  {
    title: "NSS 2018-19",
    link: "./assets/documentation/activity/nss/NSS 2018-19.pdf",
  },
  {
    title: "NSS 2019-20",
    link: "./assets/documentation/activity/nss/NSS 2019-20.pdf",
  },
  {
    title: "NSS 2020-21",
    link: "./assets/documentation/activity/nss/NSS 2020-21.pdf",
  },
  {
    title: "NSS 2021-22",
    link: "./assets/documentation/activity/nss/NSS 2021-22.pdf",
  },
];

export const softSkill = [
  {
    title: "CERTIFICATE COURSE IN ADVANCED EXCEL",
    link: "./assets/documentation/activity/soft-skill/Advanced Exel May 2023.pdf",
  },
  {
    title: "Guest lecture on Computing Skill",
    link: "./assets/documentation/activity/soft-skill/Guest lecture on Computing Skill.pdf",
  },
  {
    title: "Guest lecture on Time Managemrnt and Computing Skill",
    link: "./assets/documentation/activity/soft-skill/Guest lecture on Time Managemrnt and Computing Skill.pdf",
  },
  {
    title: "Programs under Career Guidance",
    link: "./assets/documentation/activity/soft-skill/Programs under Career Guidance.pdf",
  },
  {
    title: "Programs under Life skill",
    link: "./assets/documentation/activity/soft-skill/Programs under Life skill.pdf",
  },
  {
    title: "Programs under soft skills",
    link: "./assets/documentation/activity/soft-skill/Programs under soft skills.pdf",
  },
];

export const mouActivity = [
  {
    title: "Add on course in Medicinal Plant Tissue Culture",
    link: "./assets/documentation/activity/mou/Add on course in Medicinal Plant Tissue Culture.pdf",
  },
  {
    title: "Guest Lecture in Computing Skill",
    link: "./assets/documentation/activity/mou/Guest Lecture in Computing Skill.pdf",
  },
  {
    title: "Guest Lecture on International Womens Day",
    link: "./assets/documentation/activity/mou/Guest Lecture on International Womens Day.pdf",
  },
  {
    title: "Industrial visit to Vishwachaitanya Ayuvedic Rasashala",
    link: "./assets/documentation/activity/mou/Industrial visit to Vishwachaitanya Ayuvedic Rasashala.pdf",
  },
];
