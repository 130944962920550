import { Component, OnInit } from '@angular/core';
import { calender } from 'src/app/constant/importantLinks.constant';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.css']
})
export class AcademicComponent implements OnInit {

  readonly calender= calender;
  constructor() { }

  ngOnInit() {
  }

}
