import { Component, OnInit } from '@angular/core';
import { URLSanitizerService } from 'src/app/component/service/urlsanitizer.service';

@Component({
  selector: 'app-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.css']
})
export class PoComponent implements OnInit {

  constructor(public santizerService: URLSanitizerService) { }

  ngOnInit(): void {
  }

}
