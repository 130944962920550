import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class URLSanitizerService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitizeURL(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
