import { Component, OnInit } from "@angular/core";
import { researchList } from "./research.constant";

@Component({
  selector: "app-research",
  templateUrl: "./research.component.html",
  styleUrls: ["./research.component.css"],
})
export class ResearchComponent implements OnInit {
  readonly researchList = researchList;
  constructor() {}

  ngOnInit(): void {}
}
