import { Component, OnInit } from "@angular/core";
import { dvvList } from "src/app/constant/naac/dvv.constant";


@Component({
  selector: "app-dvv",
  templateUrl: "./dvv.component.html",
  styleUrls: ["./dvv.component.css"],
})
export class DvvComponent implements OnInit {

  readonly dvv = dvvList;
  constructor() {}

  ngOnInit(): void {}
}
