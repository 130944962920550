import { Component, OnInit } from '@angular/core';
import { timetable } from './timetable.constant'

@Component({
  selector: 'app-timetble',
  templateUrl: './timetble.component.html',
  styleUrls: ['./timetble.component.css']
})
export class TimetbleComponent implements OnInit {

  readonly timetable = timetable;
  constructor() { }

  ngOnInit(): void {
  }

}
