import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ROOT_URL } from "../../../../constant/app.constant";
import { GrievanceServiceService } from "../../../../grievance-service.service";

@Component({
  selector: "app-student-history",
  templateUrl: "./student-history.component.html",
  styleUrls: ["./student-history.component.css"],
})
export class StudentHistoryComponent implements OnInit {
  @Input("historyList") historyList: any;
  @Input("isLoggedIn") isLoggedIn: boolean;
  activeGrievance: any;

  details: any;
  constructor(
    private httpClient: HttpClient,
    private grievanceService: GrievanceServiceService
  ) {}

  ngOnInit() {}

  viewDetailsofGrievance(index: any): void {
    // = this.historyList[index];

    this.httpClient
      .get("./controller/insertGrievance.php?id=" + index)
      .subscribe(
        (next) => {
          console.log(next);
          this.activeGrievance = next;
        },
        (error) => {
          console.error("Details not available");
        }
      );
  }

  // delete perticualr Grievance
  deleteGrievance(deleteIndex: any): void {
    if (
      confirm(
        "You are about to remove the details, Are you sure You want to proceed ?"
      )
    ) {
      this.httpClient
        .delete(
          "./controller/insertGrievance.php",
          { headers: { 'CLIENT': deleteIndex } }
        )
        .subscribe(
          (next) => {
            this.grievanceService.listStudentHistory();
          },
          (error) => {
            console.error("Details not available");
          }
        );
    }
  }
}
