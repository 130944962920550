export const dvvList = [
  { title: "DVV 1.2.1", link: "./assets/documentation/naac/dvv/1/1.2.1.pdf" },
  { title: "DVV 1.2.2", link: "./assets/documentation/naac/dvv/1/1.2.2.pdf" },
  { title: "DVV 1.3.2", link: "./assets/documentation/naac/dvv/1/1.3.2.pdf" },
  { title: "DVV 1.4.1", link: "./assets/documentation/naac/dvv/1/1.4.1.pdf" },
  {
    title: "DVV 1.1 - Extended Profile",
    link: "./assets/documentation/naac/dvv/1.1.pdf",
  },
  {
    title: "DVV 2.1.1",
    link: "./assets/documentation/naac/dvv/DVV 2.1.1.pdf",
  },
  {
    title: "DVV 2.1.2",
    link: "./assets/documentation/naac/dvv/DVV 2.1.2.pdf",
  },
  {
    title: "DVV 2.2.1",
    link: "./assets/documentation/naac/dvv/DVV 2.2.1.pdf",
  },
  {
    title: "DVV 2.4.2",
    link: "./assets/documentation/naac/dvv/DVV 2.4.2.pdf",
  },
  {
    title: "DVV 2.6.3",
    link: "./assets/documentation/naac/dvv/2.6.3.pdf",
  },
  {
    title: "DVV 3.1 Extended Profile",
    link: "./assets/documentation/naac/dvv/4/Extended profile.pdf",
  },

  {
    title: "DVV 4.1.2",
    link: "./assets/documentation/naac/dvv/4/4.1.2. FINAL UPLOAD.pdf",
  },
  {
    title: "DVV 4.3.2",
    link: "./assets/documentation/naac/dvv/4/4.3.2 FINAL UPLOADING.pdf",
  },
  {
    title: "DVV 4.4.1",
    link: "./assets/documentation/naac/dvv/4/4.4.1. FINAL UPLOAD.pdf",
  },
  { title: "DVV 5.1.1", link: "./assets/documentation/naac/dvv/5/5.1.1.pdf" },
  { title: "DVV 5.1.2", link: "./assets/documentation/naac/dvv/5/5.1.2.pdf" },
  { title: "DVV 5.1.3", link: "./assets/documentation/naac/dvv/5/5.1.3.pdf" },
  { title: "DVV 5.1.4", link: "./assets/documentation/naac/dvv/5/5.1.4.pdf" },
  { title: "DVV 5.2.1", link: "./assets/documentation/naac/dvv/5/5.2.1.pdf" },
  { title: "DVV 5.2.2", link: "./assets/documentation/naac/dvv/5/5.2.2.pdf" },
  { title: "DVV 5.3.1", link: "./assets/documentation/naac/dvv/5/5.3.1.pdf" },
  { title: "DVV 5.3.2", link: "./assets/documentation/naac/dvv/5/5.3.2.pdf" },
  {
    title: "DVV 6.2.2",
    link: "./assets/documentation/naac/dvv/6/6.2.2.DVV.pdf",
  },
  {
    title: "DVV 6.3.2",
    link: "./assets/documentation/naac/dvv/6/6.3.2 DVV.pdf",
  },
  {
    title: "DVV 6.3.3",
    link: "./assets/documentation/naac/dvv/6/6.3.3 DVV.pdf",
  },
  {
    title: "DVV 6.5.2",
    link: "./assets/documentation/naac/dvv/6/6.5.2 DVV.pdf",
  },

  {
    title: "DVV 7.1.2",
    link: "./assets/documentation/naac/dvv/DVV 7.1.2.pdf",
  },
  {
    title: "DVV 7.1.3",
    link: "./assets/documentation/naac/dvv/DVV 7.1.3.pdf",
  },
];
