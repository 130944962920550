import { Component, OnInit } from '@angular/core';
import { NaacAddionalLink } from 'src/app/constant/naac/naac-additional.constant';

@Component({
  selector: 'app-naac-addional-link',
  templateUrl: './naac-addional-link.component.html',
  styleUrls: ['./naac-addional-link.component.css']
})
export class NaacAddionalLinkComponent implements OnInit {

  readonly naacAdditionalLink = NaacAddionalLink;
  constructor() { }

  ngOnInit(): void {
  }

}
