export const menuItems = [
  { name: "HOME", icon: "", link: "/" },
  {
    name: "ABOUT US",
    icon: "",
    link: "",
    subMenu: [
      { name: "INSTITUTE", link: "/aboutus" },
      { name: "PRINCIPAL'S DESK", link: "/principal" },
      { name: "FACULTY", link: "/faculty" },
      { name: "NON- TEACHING STAFF", link: "/non-teaching" },
      { name: "PROGRAM OUTCOMES", link: "/program-outcomes" },
      { name: "COURSE OUTCOMES", link: "/course-outcomes" },
    ],
  },
  { name: "ABOUT SPM", icon: "", link: "/spm" },
  {
    name: "ACTIVITIES",
    icon: "",
    link: "",
    subMenu: [
      {
        name: "MONTHLY",
        link: "/activities",
      },
      {
        name: "RESEARCH",
        link: "/activity/research",
      },
      {
        name: "NSS",
        link: "/activity/nss",
      },
      {
        name: "SOFT SKILLS",
        link: "/activity/soft-skill",
      },
      {
        name: "MOU",
        link: "/activity/mou",
      },
    ],
  },
  {
    name: "STUDENTS",
    icon: "",
    link: "",
    subMenu: [
      { name: "ADMISSION", link: "/admission" },
      { name: "ACADEMICS", link: "/academic" },
      { name: "CURRICULUM", link: "/curriculam" },
      { name: "ALUMNI", link: "/alumni" },
      { name: "PLACEMENTS", link: "/TnPcell" },
      { name: "LIBRARY", link: "/library" },
      { name: "FEEDBACK", link: "/student/feedback" },
    ],
  },

  { name: "GOVERNANCE", icon: "", link: "/committee" },
  { name: "GALLERY", icon: "", link: "/gallery" },
  { name: "CAREER", icon: "", link: "/career" },
  {
    name: "IQAC",
    icon: "",
    link: "./assets/documentation/iqac/IQACCompositionfor2022-23to2023-24.pdf",
    subMenu: [
      { name: "IQAC COMPOSITION", link: "/iqac-composition" },
      { name: "MINUTES OF MEETING", link: "/mom" },
      { name: "ACTION TAKEN REPORTS", link: "/action-taken" },
      /*             { name: "IQAC Committee", link: "/iqac-committee" },
            { name: "Institutional Distinctiveness", link: "/institutional-distinctiveness" },
            { name: "Procedure and Policies", link: "/procedure-policies" },
            { name: "Best Practises", link: "/best-practises", },
            { name: "AQAR", link: "/aqar", },
            { name: "AQAR Documents", link: "/aqar-docs", }, */
    ],
  },
  {
    name: "NAAC",
    icon: "",
    link: "/NAAC",
    subMenu: [
      { name: "IIQA", link: "/iiqa" },
      { name: "SSR", link: "/ssr" },
      { name: "DVV", link: "/dvv" },
      { name: "ADDITIONAL LINKS", link: "/naac-addional-links" },
      { name: "BEST PRACTICES", link: "/best-practices" },
      { name: "INSTITUTIONAL DISTINCTIVENESS", link: "/instiddistinct" },
    ],
  },
  /*     {
        name: "NIRF", icon: "", link: "/NAAC"
    }, */
  { name: "GRIEVANCE PORTAL", icon: "", link: "/grievance" },
  { name: "CONTACT US", icon: "", link: "/contact" },
];
