import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-aqar',
  templateUrl: './iqac-aqar.component.html',
  styleUrls: ['./iqac-aqar.component.css']
})
export class IqacAqarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
