import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-proc-policies',
  templateUrl: './iqac-proc-policies.component.html',
  styleUrls: ['./iqac-proc-policies.component.css']
})
export class IqacProcPoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
