import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-committee',
  templateUrl: './iqac-committee.component.html',
  styleUrls: ['./iqac-committee.component.css']
})
export class IqacCommitteeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
