import { getMaxListeners } from "process";

export const applicationName = 'College of Pharmacy, Akluj';
export const facultyDetails = [{
    'name': 'Dr. A. S. Bhanwase',
    'designation': 'Professor & I/C Principal',
    'Qualification': 'M.Pharm, Ph. D.',
    'specialization': 'Pharmaceutical Chemistry',
    'email': 'anil.bhanawase@gmail.com'
} , {
    'name': 'Dr. M. M. Gade',
    'designation': ' Professor',
    'Qualification': 'M.Pharm, Ph. D.',
    'specialization': 'Pharmaceutics',
    'email': 'mukundgade09@gmail.com'
}, {
    'name': 'Mrs. N. S. Kajale',
    'designation': 'Associate Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutics',
    'email': 'cssgrs@gmail.com'
},{
    'name': 'Miss. P. G. Shinde',
    'designation': 'Associate Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutical Chemistry',
    'email': 'prashali.shinde@gmail.com'
},{
    'name': 'Miss. P. N. Shinde',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutical Chemistry',
    'email': 'devishree.shinde7@gmail.com'
},{
    'name': 'Miss. S. V. Sawant',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutics',
    'email': 'sanjivanivsawant@gmail.com'
},{
    'name': 'Mr. M. D. Mitkal',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutical Chemistry',
    'email': 'mahesh.mitkal2@gmail.com'
},{
    'name': 'Mr. S. H. Rajeghadage',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacutics',
    'email': 'swapnilrajeghadage@gmail.com'
},{
    'name': 'Miss. S. H. Kharat',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacology',
    'email': 'shubhangi.kharat1994@gmail.com'
},{
    'name': 'Mr. B. S. Ankalgi',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutics',
    'email': 'bapuankalgi13@gmail.com'
},{
    'name': 'Mr. R. N. Jalkote',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmaceutical Quality Assurance',
    'email': 'ravirajjalkote@gmail.com'
},{
    'name': 'Mrs. R. V. Pawar',
    'designation': 'Associate Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacology',
    'email': 'reshmavishalpawar2019@gmail.com'
},{
    'name': 'Miss. S. U. Kashid',
    'designation': 'Associate Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacognosy',
    'email': 'snehalkashid1231@gmail.com'
},{
    'name': 'Miss. A. U. Phutane',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacognosy',
    'email': 'aishuphutanehby10@gmail.com'
},{
    'name': 'Miss. P. D. Bagal. ',
    'designation': 'Assistant Professor',
    'Qualification': 'M.Pharm.',
    'specialization': 'Pharmacology',
    'email': ''
},{
    'name': 'Miss. A.R. Rahane',
    'designation': 'Lecture (CHB)',
    'Qualification': 'M.Sc. B.Ed, SET',
    'specialization': 'Mathematics',
    'email': 'ashwinirahane18@gmail.com'
}];