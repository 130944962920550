import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-iqac-action-taken",
  templateUrl: "./iqac-action-taken.component.html",
  styleUrls: ["./iqac-action-taken.component.css"],
})
export class IqacActionTakenComponent implements OnInit {
  atrList = [
    {
      title: "Action Taken Report 22.11.2018",
      link: "./assets/documentation/iqac/atr/1.ATR 22.11.2018.pdf",
    },
    {
      title: "Action Taken Report 09.05.2019",
      link: "./assets/documentation/iqac/atr/2.ATR 09.05.2019.pdf",
    },
    {
      title: "Action Taken Report 07.08.2019",
      link: "./assets/documentation/iqac/atr/3.ATR 07.08.2019.pdf",
    },
    {
      title: "Action Taken Report 09.07.2020",
      link: "./assets/documentation/iqac/atr/4.ATR 09.07.2020.pdf",
    },
    {
      title: "Action Taken Report 17.08.2020",
      link: "./assets/documentation/iqac/atr/5.ATR 17.08.2020.pdf",
    },
    {
      title: "Action Taken Report 18.06.2021",
      link: "./assets/documentation/iqac/atr/6.ATR 18.06.2021.pdf",
    },
    {
      title: "Action Taken Report 09.09.2021",
      link: "./assets/documentation/iqac/atr/7.ATR 09.09.2021.pdf",
    },
    {
      title: "Action Taken Report 21.05.2022",
      link: "./assets/documentation/iqac/atr/8.ATR 21.05.2022.pdf",
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
