export const ssrConstants = [
  {
    name: "Criterion 1",
    subpoint: [
      {
        name: "Curricular Planning and Implementation",
        point: "1.1",
        secondSub: [
          {
            point: "1.1.1",
            name: "Effective curriculum delivery through planned and documented process'",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c1/1.1.1/1.1.1Curriculum Planning.pdf",
                name: "Additional information	",
              },
              {
                link: "./assets/documentation/naac/ssr/c1/1.1.1/1.1.1Curriculum implementation.pdf",
                name: "Supportive Documents",
              },
            ],
          },
        ],
      },
      {
        name: "Acedemic Flexibility",
        point: "1.2",
        secondSub: [
          {
            point: "1.2.1",
            name: "Number of Certificate/Value added courses offered and online courses of MOOCs, SWAYAM, NPTEL etc.",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c1/1.2.1/1.2.1.  Certificate Courses.pdf",
                name: "Supportive Documents",
              },
              {
                name: "List of Stdudents and Attendance Sheet for the above mentioned programs",
                link: "./assets/documentation/naac/ssr/c1/1.2.1/1.2.1. A) List of Stdudents and Attendance Sheets.pdf",
              },
              {
                name: "Institutiona Program Brochure Notice for Certificate Course modules and outcome.pdf",
                link: "./assets/documentation/naac/ssr/c1/1.2.1/1.2.1. B) Institutiona Program Brochure  for Certificate Course.pdf",
              },
              {
                name: "Evidance of course completion certificate Apart from the above.pdf",
                link: "./assets/documentation/naac/ssr/c1/1.2.1/1.2.1. C) Evidance of course completion certificate.pdf",
              },
              {
                name: "Something Revised",
                link: "./assets/documentation/naac/ssr/c1/1.2.1/1.2.1. REVISED.pdf",
              },
            ],
          },
          {
            point: "1.2.2",
            name: "Percentage of students enrolled in Certificate/ Add-on programs",
            alphaSub: [],
            link: "",
          },
          {
            point: "1.2.2",
            name: "Number of students enrolled in Certificate/ Add-on programs",
            alphaSub: [{ link: "", name: "Additional information	" }],
            link: "",
          },
        ],
      },
      {
        name: "Curriculum Enrichment",
        point: "1.3",
        secondSub: [
          {
            point: "1.3.1",
            name: "Integration of crosscutting issues relevant to Professional Ethics, Gender, Human Values, Environment and Sustainability into the Curriculum",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c1/1.3.1. Curriculum Enrichment.pdf",
                name: "Additional information	",
              },
              {
                link: "./assets/documentation/naac/ssr/c1/1.3.1. Curriculum Enrichment.pdf",
                name: "Supportive Documents",
              },
            ],
            link: "",
          },
          {
            point: "1.3.2",
            name: "Percentage of students undertaking project work/field work/ internships",
            alphaSub: [
              {
                name: "1.3.2.1 Number of students undertaking project work/field work / internships",
                link: "./assets/documentation/naac/ssr/c1/1.3.2. Project Report.pdf",
              },
            ],
            link: "",
          },
          {
            point: "1.3.2.1",
            name: "Number of students undertaking project work/field work / internships",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c1/1.3.2. Project Report.pdf",
                name: "Additional information	",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: "Feedback System",
        point: "1.4",
        secondSub: [
          {
            point: "1.4.1",
            name: "Feedback on the academic performance and ambience of the institution from various stakeholders",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/CR1/1.4.1 .Feedback system.pdf",
                name: "Supportive Documents",
              },
              {
                link: "./assets/documentation/naac/ssr/c1/1.4.1/1.4.1 .Feedback system.pdf",
                name: "Link of Institutions Website where Comprehensive Feedback , its analytics and Action taken reports",
              },

              {
                name: "Feedback Analysis Report",
                link: "./assets/documentation/naac/ssr/c1/1.4.1/1.4.1 A) Feedback Analysis Report.pdf",
              },
              {
                name: "Filled form from Different Stake Holders",
                link: "./assets/documentation/naac/ssr/c1/1.4.1/1.4.1. B) Filled form from Different Stake Holders.pdf",
              },
              {
                name: "Action Taken Report",
                link: "./assets/documentation/naac/ssr/c1/1.4.1/1.4.1. C) Action Taken Report.pdf",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Criterion 2",
    subpoint: [
      {
        name: "Student Enrollment and Profile",
        point: "2.1",
        secondSub: [
          {
            point: "2.1.1",
            name: "Enrolment percentage",
            alphaSub: [
              {
                name: "2.1.1.2: Number of sanctioned seats year wise during last five years",
                link: "",
              },
            ],
            link: "",
          },
          {
            point: "2.1.1.1",
            name: "Number of seats filled year wise during last five years",
            alphaSub: [],
            link: "",
          },
          {
            point: "2.1.1.2",
            name: "Number of sanctioned seats year wise during last five years",
            alphaSub: [
              {
                name: "A) Links for document to support the claim",
                link: "./assets/documentation/naac/ssr/c2/2.1.1/2.1.1.2.pdf",
              },
              {
                name: "B) Final admission list as published by the HEI",
                link: "./assets/documentation/naac/ssr/c2/2.1.1/2.1.1 B).pdf",
              },
              {
                name: "C) Document related to sanction of intake ",
                link: "./assets/documentation/naac/ssr/c2/2.1.1/2.1.1.pdf",
              },
            ],
            link: "",
          },

          {
            point: "2.1.2",
            name: "Percentage of seats filled against reserved categories (SC, ST, OBC etc.)",
            alphaSub: [],
            link: "",
          },
          {
            point: "2.1.2.1",
            name: "Number of actual students admitted from the reserved categories year wise during last five years",
            alphaSub: [],
            link: "",
          },
          {
            point: "2.1.2.2",
            name: "Number of seats earmarked for reserved category as per GOI/ State Govt rule year wise during the last five years",
            alphaSub: [
              {
                name: "Links for  relevant document",
                link: "./assets/documentation/naac/ssr/c2/2.1.2/2.1.2 A).pdf",
              },
              {
                name: "Final admission list indicating the category as published by the HEI ",
                link: "./assets/documentation/naac/ssr/c2/2.1.2/2.1.2 B).pdf",
              },
              {
                name: "Copy of communication issued by state govt. or Central Government    ",
                link: "./assets/documentation/naac/ssr/c2/2.1.2/2.1.2.pdf",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: "Student Teacher Ratio",
        point: "2.2",
        secondSub: [
          {
            point: "2.2.1",
            name: "Student – Full time Teacher Ratio",
            alphaSub: [],
            link: "",
          },
        ],
      },
      {
        name: "Teaching- Learning Process",
        point: "2.3",
        secondSub: [
          {
            point: "2.3.1",
            name: "Student centric methods",
            alphaSub: [
              {
                name: " Additional Information",
                link: "./assets/documentation/naac/ssr/c2/2.3.1.pdf",
              },
              {
                name: "Supportive Documents",
                link: "./assets/documentation/naac/ssr/c2/2.3.1.pdf",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: "Teacher Profile and Quality",
        point: "2.4",
        secondSub: [
          {
            point: "2.4.1",
            name: "Percentage of full time teachers against sanctioned posts",
            alphaSub: [],
            link: "",
          },
          {
            point: "2.4.1.1",
            name: "Number of sanctioned posts year wise during the last five years",
            alphaSub: [
              {
                name: "Sanction letters indicating number of posts",
                link: "./assets/documentation/naac/ssr/c2/2.4.1.pdf",
              },
              {
                name: "Relevant Document Link",
                link: "./assets/documentation/naac/ssr/c2/2.4.1.pdf",
              },
            ],
            link: "",
          },
          {
            point: "2.4.2",
            name: "Percentage of full time teachers with NET/SET/SLET/ Ph. D./D.Sc. / D.Litt./L.L.D.",
            alphaSub: "",
            link: "",
          },
          {
            point: "2.4.2.1",
            name: "Number of full time teachers with NET/SET/SLET/ Ph. D./D.Sc. / D.Litt./L.L.D.",
            alphaSub: [
              {
                name: "Links for any other relevant document ",
                link: "./assets/documentation/naac/ssr/c2/2.4.2/2.4.2 A).pdf",
              },
              {
                name: "List of faculties having Ph. D. / D.Sc. / D.Litt./ L.L.D ",
                link: "./assets/documentation/naac/ssr/c2/2.4.2/2.4.2 B).pdf",
              },
              {
                name: "Copies of Ph.D./D.Sc / D.Litt./ L.L.D awareded           ",
                link: "./assets/documentation/naac/ssr/c2/2.4.2/2.4.2.pdf",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: "Evaluation Process and Reforms",
        point: "2.5",
        secondSub: [
          {
            point: "2.5.1",
            name: "Mechanism of internal / external assessment is transparent and the grievance redressal system is time- bound and efficient",
            alphaSub: [
              {
                name: "Additional Information",
                link: "./assets/documentation/naac/ssr/c2/2.5.1.pdf",
              },
              {
                name: "Relevant document link",
                link: "./assets/documentation/naac/ssr/c2/2.5.1.pdf",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: "Student Performance and Learning Outcomes",
        point: "2.6",
        secondSub: [
          {
            point: "2.6.1",
            name: "Programme and course outcomes of Programmes",
            alphaSub: [
              {
                name: "Additional Information",
                link: "./assets/documentation/naac/ssr/c2/2.6.1.pdf",
              },
              {
                name: "Relevant document link",
                link: "./assets/documentation/naac/ssr/c2/2.6.1.pdf",
              },
            ],
            link: "",
          },
          {
            point: "2.6.2",
            name: "Attainment of programme outcomes and course outcomes",
            alphaSub: [
              {
                name: "Additional Information",
                link: "./assets/documentation/naac/ssr/c2/2.6.2.pdf",
              },
              {
                name: "Relevant document link",
                link: "./assets/documentation/naac/ssr/c2/2.6.2.pdf",
              },
            ],
            link: "",
          },
          {
            point: "2.6.3",
            name: "Average pass percentage of Students",
            alphaSub: "",
            link: "",
          },
          {
            point: "2.6.3.1",
            name: "Number of final year students who passed the university examination",
            alphaSub: "",
            link: "",
          },
          {
            point: "2.6.3.2",
            name: "Number of final year students who appeared for the university examination",
            alphaSub: [
              {
                name: "Links for any other relevant document ",
                link: "./assets/documentation/naac/ssr/c2/2.6.3.pdf",
              },
              {
                name: "Certified report from Controller Examination of the affiliating university",
                link: "",
              },
              {
                name: "Annual report of controller of Examinations(COE)",
                link: "",
              },
            ],
            link: "",
          },
        ],
      },
      {
        name: " Student Satisfaction Survey",
        point: "2.7",
        secondSub: [
          {
            oint: "2.7.1",
            name: "Online student satisfaction survey regarding teaching learning process",
            alphaSub: "",
            link: "",
          },
        ],
      },
    ],
  },
  {
    name: "Criterion 3",
    subpoint: [
      {
        point: "3.1",
        name: "Resource Mobilization for Research",
        secondSub: [
          {
            point: "3.1.1",
            name: "Grants received from Government and non-governmental agencies for research projects / endowments",
            alphaSub: [],
          },
          {
            point: "3.1.1.1",
            name: "Total Grants from Government and non-governmental agencies for research projects",
            alphaSub: [
              {
                name: "Upload supporting document",
                link: "./assets/documentation/naac/ssr/c3/3.1.1.pdf",
              },
            ],
          },
        ],
      },
      {
        point: "3.2",
        name: "Innovation Ecosystem	",
        secondSub: [
          {
            point: "3.2.1",
            name: "ecosystem for innovations, Indian Knowledge System (IKS),including awareness   about IPR, establishment of IPR cell, Incubation centre and other initiatives",
            alphaSub: [
              {
                name: "Additional Information",
                link: "./assets/documentation/naac/ssr/c3/3.2.1.pdf",
              },
              {
                name: "Supportive Documents  ",
                link: "./assets/documentation/naac/ssr/c3/3.2.1.pdf",
              },
            ],
          },
          {
            point: "3.2.2",
            alphaSub: [],
            name: "Number of workshops/seminars/conferences including on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship",
          },
          {
            point: "3.2.2.1",
            alphaSub: [
              {
                name: "Upload supporting document",
                link: "./assets/documentation/naac/ssr/c3/3.2.2.pdf",
              },
            ],
            name: "Total number of workshops/seminars/conferences including programs conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship year wise during last five years",
          },
        ],
      },
      {
        point: "3.3",
        name: "Research Publications and Awards",
        secondSub: [
          {
            point: "3.3.1",
            alphaSub: [],
            name: "Number of research papers published per teacher in the Journals notified on UGC care list during the last five years",
          },
          {
            point: "3.3.1.1",
            alphaSub: [
              {
                link: "",
                name: "relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.3.1/3.3.1  A).pdf",
                name: "Uploaded papers, the first page/full paper(with author and affiliation details)on the institutional website",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.3.1/3.3.1 B).pdf",
                name: "Re-directing to journal source-cite website in case of digital journals",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.3.1/3.3.1.pdf",
                name: "Papers published in journals listed in UGC CARE list",
              },
            ],
            name: "Number of research papers in the Journals notified on UGC CARE list year wise during the last five years",
          },
          {
            point: "3.3.2",
            alphaSub: [],
            name: "Number of books and chapters in edited volumes/books published and papers published in national/ international conference",
          },
          {
            point: "3.3.2.1",
            alphaSub: [
              {
                link: "",
                name: "relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.3.2 split.pdf",
                name: "List of chapter/book along with the links redirecting to the source website",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.3.2.pdf",
                name: "Copy of the Cover page, content page and first page of the publication indicating ISBN number and year of publication for books/chapters",
              },
            ],
            name: "Total number of books and chapters in edited volumes/books published and papers in national/ international conference proceedings year wise during last five years",
          },
        ],
      },
      {
        point: "3.4",
        name: "Extension Activities",
        secondSub: [
          {
            point: "3.4.1",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c3/3.4.1.pdf",
                name: "Additional information	",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.4.1.pdf",
                name: "Supportive Documents",
              },
            ],
            name: "Outcomes of Extension activities in the neighborhood community in terms of impact and sensitizing the students to social issues for their holistic development during the last five years.",
          },
          {
            point: "3.4.2",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c3/3.4.2.pdf",
                name: "Additional information	",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.4.2.pdf",
                name: "Supportive Documents",
              },
            ],
            name: "Awards and recognitions received for extension activities from government / government recognised bodies",
          },
          {
            point: "3.4.3",
            alphaSub: [],
            name: "Number of extension and outreach programs conducted by the institution through organized forums including NSS/NCC with involvement of community during the last five years.",
          },
          {
            point: "3.4.3.1",
            alphaSub: [
              {
                link: "",
                name: "Relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c3/3.4.3.pdf",
                name: "Photographs and any other supporting document",
              },
              {
                link: "",
                name: "Detailed report for each extension and outreach program ",
              },
            ],
            name: "Number of extension and outreach Programs conducted in collaboration with industry, community, and Non- Government Organizations through NSS/ NCC etc., year wise during the last five years",
          },
        ],
      },
      {
        point: "3.5",
        name: "Collaboration",
        secondSub: [
          {
            point: "3.5.1",
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c3/3.5.1.pdf",
                name: "	Summary of the functional MoUs/linkage/collaboration indicating start date, end date, nature of collaboration etc.",
              },
              {
                link: "",
                name: "	Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "",
                name: "	List of year wise activities and exchange should be provided",
              },
              {
                link: "",
                name: "	List and Copies of documents indicating the functional MoUs/linkage/collaborations activity-wise and year-wise",
              },
            ],
            name: "Number of functional MoUs/linkages with institutions/ industries in India and abroad for internship, on-the-job training, project work, student / faculty exchange and collaborative research during the last five years.",
          },
        ],
      },
    ],
  },
  {
    name: "Criterion 4",
    subpoint: [
      {
        point: "4.1",
        name: "Physical Facilities",
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c4/4.1.1.pdf",
                name: "teaching – learning, viz., classrooms, laboratories, computing equipment etc",
              },
              {
                link: "",
                name: "ICT – enabled facilities such as smart class, LMS etc.",
              },
              {
                link: "",
                name: "Facilities for Cultural and sports activities, yoga centre, games (indoor and outdoor), Gymnasium, auditorium etc (Describe the adequacy of facilities in maximum of 500 words.)",
              },
              { link: "", name: "Additional information		" },
              { link: "", name: "Supportive Documents		" },
            ],
            point: "4.1.1",
            name: "The Institution has adequate infrastructure and other facilities for,",
          },
          {
            alphaSub: [],
            point: "4.1.2",
            name: "Percentage of expenditure for infrastructure development and  augmentation excluding salary during the last five years",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c4/4.1.2.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c4/4.1.2.pdf",
                name: "Audited income and expenditure statement of the institution to be signed by CA for and counter signed by the competent authority (relevant expenditure claimed for infrastructure augmentation should be clearly highlighted)",
              },
            ],
            point: "4.1.2.1",
            name: "Expenditure for infrastructure development and  augmentation, excluding salary year wise during last five years (INR in lakhs)",
          },
        ],
      },
      {
        point: "4.2",
        name: "Library as a Learning Resource",
        secondSub: [
          {
            alphaSub: [{link:"./assets/documentation/naac/ssr/c4/4.2.1.pdf", name:"Addional Documents"}],
            point: "4.2.1",
            name: "Library is automated with digital facilities using Integrated Library Management System (ILMS), adequate subscriptions to e-resources and journals are made.  The library is optimally used by the faculty and students",
          },
        ],
      },
      {
        point: "4.3",
        name: "IT Infrastructure",
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c4/4.3.1.pdf",
                name: "Additional information		",
              },
              {
                link: "./assets/documentation/naac/ssr/c4/4.3.1.pdf",
                name: "Supportive Documents		",
              },
            ],
            point: "4.3.1",
            name: "Institution frequently updates its IT facilities and provides sufficient bandwidth for internet connection Describe IT facilities including Wi-Fi with date and nature of updation, available internet bandwidth within a maximum of 500 words",
          },
          {
            alphaSub: [],
            point: "4.3.2",
            name: "Student – Computer ratio (Data for the latest completed academic year)",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c4/4.3.2  split I.pdf",
                name: "Purchased Bills/Copies highlighting the number of computers purchased		",
              },
              {
                link: "./assets/documentation/naac/ssr/c4/4.3.2 split II.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "./assets/documentation/naac/ssr/c4/4.3.2.pdf",
                name: "Extracts stock register/ highlighting the computers issued to respective departments for student’s usage.		",
              },
            ],
            point: "4.3.2.1",
            name: "Number of computers available for students usage during the latest completed academic year:",
          },
        ],
      },
      {
        point: "4.4",
        name: "Maintenance of Campus Infrastructure",
        secondSub: [
          {
            alphaSub: [],
            point: "4.4.1",
            name: "Percentage expenditure incurred on maintenance of physical facilities and academic support facilities excluding salary component, during the last five years (INR in Lakhs) ",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c4/4.4.1.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "./assets/documentation/naac/ssr/c4/4.4.1.pdf",
                name: "Audited income and expenditure statement of the institution to be signed by CA for and counter signed by the competent authority (relevant expenditure claimed for maintenance of infrastructure should be clearly highlighted)		",
              },
            ],
            point: "4.4.1.1",
            name: "Expenditure incurred on maintenance of infrastructure (physical facilities and academic support facilities) excluding salary component year wise during the last five years (INR in lakhs)",
          },
        ],
      },
    ],
  },
  {
    name: "Criterion 5",
    subpoint: [
      {
        secondSub: [
          {
            alphaSub: [],
            point: "5.1.1",
            name: "Percentage of students benefited by scholarships and freeships ",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.1. 1 (SCHOLARSHIP).pdf",
                name: "Year-wise list of beneficiary students",
              },
              {
                link: "",
                name: "Sanction letter of scholarship and free ships (along with English translated version if it is in regional language).",
              },
              {
                link: "",
                name: "Policy document of the HEI for award of scholarship and freeships.",
              },
              {
                link: "",
                name: "Links for any other relevant document to support the claim (if any)",
              },
            ],
            point: "5.1.1.1",
            name: "Number of students benefited by scholarships and freeships 	",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.1. 2.pdf",
                name: "Report with photographs on Programmes /activities conducted to enhance soft skills, Language and communication skills, and Life skills",
              },
              {
                link: "",
                name: "Report with photographs on ICT/computing skills enhancement programs",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.1. 2.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
            ],
            point: "5.1.2",
            name: "Following capacity development and skills enhancement activities are organised for improving students’ capability Soft skills",
          },

          {
            alphaSub: [],
            point: "5.1.3",
            name: "Percentage of students benefitted by guidance for competitive examinations and career counseling offered by the Institution during the last five years",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.1.3.pdf",
                name: "Upload supporting document",
              },
            ],
            point: "5.1.3.1",
            name: "Number of students benefitted by guidance for competitive examinations and career counselling offered by the institution  year wise during last five years",
          },

          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.1.4.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "",
                name: "Proof w.r.t Organisation wide awareness and undertakings on policies with zero tolerance",
              },
              {
                link: "",
                name: "Proof related to Mechanisms for submission of online/offline students’ grievances",
              },
              {
                link: "",
                name: "Proof for Implementation of guidelines of statutory/regulatory bodies",
              },
              {
                link: "",
                name: "Details of statutory/regulatory Committees (to be notified in institutional website also)",
              },
              {
                link: "",
                name: "Annual report of the committee motioning the activities and number of grievances redressed to prove timely redressal of the grievances",
              },
            ],
            point: "5.1.4",
            name: "The institution adopts the following for redressal of student grievances including sexual harassment and ragging cases",
          },
        ],
        point: "5.1",
        name: "Student Support",
      },
      {
        secondSub: [
          {
            alphaSub: [],
            point: "5.2.1",
            name: "Percentage of placement of outgoing students and students progressing to higher education during the last five years",
          },
          {
            alphaSub: [],
            point: "5.2.1.1",
            name: "Number of outgoing students placed and / or progressed to higher education year wise during the last five years",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.2.2 GPAT.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "/assets/documentation/naac/ssr/c5/5.2 (5.2.1.2) Placement.pdf",
                name: "Number and List of students placed along with placement details ",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.2 (5.2.1.1 ) Higher education.pdf",
                name: "List of students progressing for Higher Education, with details of program and institution	",
              },
            ],
            point: "5.2.1.2",
            name: "Number of outgoing students year wise during the last five years",
          },

          {
            alphaSub: [],
            point: "5.2.2",
            name: "Percentage of students qualifying in state/national/ international level examinations during the last five years",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.2.2 GPAT.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.2.2 GPAT.pdf",
                name: "List of students qualified year wise under each category and links to Qualifying Certificates of the students taking the examination",
              },
            ],
            point: "5.2.2.1",
            name: "Number of students qualifying in state/ national/ international level examinations ",
          },
        ],
        point: "5.2",
        name: "Student Progression",
      },
      {
        secondSub: [
          {
            alphaSub: [],
            point: "5.3.1",
            name: "Number of awards/medals for outstanding performance in sports/ cultural activities at University / state/ national / international level",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.3 (5.3.1).pdf",
                name: "supporting document",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.3 (5.3.1).pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.3 (5.3.1).pdf",
                name: "list and links to e-copies of award letters and certificates	",
              },
            ],
            point: "5.3.1.1",
            name: "Number of awards/medals for outstanding performance in sports/cultural activities at  national/international level ",
          },

          {
            alphaSub: [],
            point: "5.3.2",
            name: "Average number of sports and cultural programs in which students of the Institution participated during last five years (organised by the institution/other institutions)",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.3 (5.3.2).pdf",
                name: "Upload supporting document",
              },
            ],
            point: "5.3.2.1",
            name: "Number of sports and cultural programs in which students of the Institution participated year wise during last five years",
          },
        ],
        point: "5.3",
        name: "Student Participation and Activities",
      },
      {
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c5/5.4 (ALUMNI).pdf",
                name: "Additional information",
              },
              {
                link: "./assets/documentation/naac/ssr/c5/5.4 (ALUMNI).pdf",
                name: "Supportive Documents",
              },
            ],
            point: "5.4.1",
            name: "There is a registered Alumni Association that contributes significantly to the development of the institution through financial and/or other support services",
          },
        ],
        point: "5.4",
        name: "Alumni Engagement",
      },
    ],
  },
  {
    name: "Criterion 6",
    subpoint: [
      {
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c6/6.1.1 Link 1.pdf",
                name: "Supportive Documents		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.1.1 Link 2.pdf",
                name: "SuppAddiotnal Documents		",
              },
            ],
            point: "6.1.1",
            name: "The institutional governance and leadership ",
          },
        ],
        point: "6.1",
        name: "Institutional Vision and Leadership",
      },

      {
        secondSub: [
          {
            alphaSub: [
              { link: "./assets/documentation/naac/ssr/c6/6.2.1 Link 1.pdf", name: "Supportive Documents		" },
              {
                link: "./assets/documentation/naac/ssr/c6/6.2.1 Link 2.pdf",
                name: "Institutional perspective Plan and deployment documents on the website		",
              },
            ],
            point: "6.2.1",
            name: "The institutional perspective plan is effectively deployed and functioning.",
          },

          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c6/6.2.2 Link 1.pdf",
                name: "Screen shots of user interfaces of each module reflecting the name of the HEI		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.2.2 Link 2.pdf",
                name: "Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.2.2 Link 3.pdf",
                name: "Institutional expenditure statements for the budget heads of e-governance implementation ERP Document		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.2.2 Link 4.pdf",
                name: "Annual e-governance report approved by the Governing Council/ Board of Management/ Syndicate Policy document on e-governance		",
              },
            ],
            point: "6.2.2",
            name: "Institution implements e-governance in its operation",
          },
        ],
        point: "6.2",
        name: "Strategy Development and Deployment",
      },

      {
        secondSub: [
          {
            alphaSub: [
              { link: "./assets/documentation/naac/ssr/c6/6.3.1 Link 1.pdf", name: "Additional information		" },
              { link: "./assets/documentation/naac/ssr/c6/6.3.1 Link 2.pdf", name: "Supportive Documents		" },
            ],
            point: "6.3.1",
            name: "The institution has performance appraisal system, effective welfare measures for teaching and non-teaching staff.",
          },

          {
            alphaSub: [],
            point: "6.3.2",
            name: "Percentage of teachers provided with financial support to attend conferences/workshops",
          },
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c6/6.3.2 Link 1.pdf",
                name: "Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "",
                name: "Policy document on providing financial support to teachers		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.3.3 Link 1.pdf",
                name: "Copy of letter/s indicating financial assistance to teachers and list of teachers receiving financial support year-wise under each head.		",
              },
              {
                link: "",
                name: "Audited statement of account highlighting the financial support to teachers to attend conferences / workshop s and towards membership fee for professional bodies		",
              },
            ],
            point: "6.3.2.1",
            name: "Number of teachers provided with financial support to attend conferences/workshops",
          },

          {
            alphaSub: [{
              link:"./assets/documentation/naac/ssr/c6/6.3.3 Link 1.pdf", name:"6.3.3"
            }],
            point: "6.3.3",
            name: "Percentage of teaching and non-teaching staff participating in Faculty development Programmes (FDP), Management Development Programmes (MDPs) professional development /administrative training programs during the last five years",
          },
          {
            alphaSub: [],
            point: "6.3.3.1",
            name: "Total number of teaching and non-teaching staff participating in Faculty development Programmes (FDP), Management Development Programmes (MDPs) professional development /administrative training programs during the last five years",
          },
          {
            alphaSub: [
              {
                link: "",
                name: "Refresher course/Faculty Orientation or other programmes as per UGC/AICTE stipulated periods, as participated by teachers year-wise.		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.3.3 Link 1.pdf",
                name: "Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "",
                name: "Copy of the certificates of the program attended by teachers.		",
              },
              {
                link: "",
                name: "Annual reports highlighting the programmes undertaken by the teachers		",
              },
            ],
            point: "6.3.3.2",
            name: "Number of non-teaching staff year wise during the last five years",
          },
        ],
        point: "6.3",
        name: "Faculty Empowerment Strategies",
      },

      {
        secondSub: [
          {
            alphaSub: [
              { link: "./assets/documentation/naac/ssr/c6/6.4.1 Link 1.pdf", name: "Additional information		" },
              { link: "./assets/documentation/naac/ssr/c6/6.4.1 Link 1.pdf", name: "Supportive Documents		" },
            ],
            point: "6.4.1",
            name: "Institution has strategies for mobilization and optimal utilization of resources and funds from various sources (government/ nongovernment organizations) and it conducts financial audits regularly (internal and external)",
          },
        ],
        point: "6.4",
        name: "Financial Management and Resource Mobilization",
      },

      {
        secondSub: [
          {
            alphaSub: [
              { link: "./assets/documentation/naac/ssr/c6/6.5.1 Internal Quality Assurance System.pdf", name: "Additional information		" },
              { link: "./assets/documentation/naac/ssr/c6/6.5.1 Internal Quality Assurance System.pdf", name: "Supportive Documents		" },
            ],
            point: "6.5.1",
            name: "Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes. It reviews teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals and records the incremental improvement in various activities",
          },

          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c6/6.5.2  Main Link.pdf",
                name: "Quality audit reports/certificate as applicable and valid for the assessment period.		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.5.2  Main Link.pdf",
                name: "Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "",
                name: "NIRF report, AAA report and details on follow up actions		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.5.2  Main Link.pdf",
                name: "List of Collaborative quality initiatives with other institution(s) along with brochures and geo-tagged photos with caption and date.		",
              },
              {
                link: "./assets/documentation/naac/ssr/c6/6.5.2 Link 2.pdf",
                name: "Link to Minute of IQAC meetings, hosted on HEI website	",
              },
            ],
            point: "6.5.2",
            name: "Quality assurance initiatives of the institution ",
          },
        ],
        point: "6.5",
        name: "Internal Quality Assurance System",
      },
    ],
  },
  {
    name: "Criterion 7",
    subpoint: [
      {
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.1.pdf",
                name: "Additional information		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.1.pdf",
                name: "Supportive Documents		",
              },
            ],
            point: "7.1.1",
            name: "Institution has initiated the Gender Audit and measures for the promotion of gender equity during the last five years. ",
          },

          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.2.pdf",
                name: "Provide Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/Split/7.1.2 Split Final Upload Policy document.pdf",
                name: "Policy document on the green campus/plastic free campus.",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/Split/7.1.2 Split Final Upload Geotagged photographs.pdf",
                name: "Geo-tagged photographs/videos of the facilities.		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/Split/7.1.2 Split Final Upload Circulars.pdf",
                name: "Circulars and report of activities for the implementation of the initiatives document		",
              },
              {
                link: "",
                name: "Bills for the purchase of equipment’s for the facilities created under this metric		",
              },
            ],
            point: "7.1.2",
            name: "The Institution has facilities and initiatives for Alternate sources of energy and energy conservation measures,Management of the various types of degradable and nondegradable waste Water conservation,Green campus initiatives, Disabled-friendly, barrier free environment",
          },

          {
            alphaSub: [
              {
                link: "",
                name: "Report on Environmental Promotional activities conducted beyond the campus with geo tagged photographs with caption and date		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.3.pdf",
                name: "Links for any other relevant document to support the claim (if any)		",
              },
              {
                link: "",
                name: "Policy document on environment and energy usage Certificate from the auditing agency		",
              },
              {
                link: "",
                name: "Green audit/environmental audit report from recognized bodies		",
              },
              {
                link: "",
                name: "Certificates of the awards received from recognized agency (if any).		",
              },
            ],
            point: "7.1.3",
            name: "Quality audits on environment and energy regularly undertaken by the Institution. ",
          },

          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.4.pdf",
                name: "Additional information		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/7.1.4.pdf",
                name: "Supportive Documents		",
              },
            ],
            point: "7.1.4",
            name: "Describe the Institutional efforts/initiatives in providing an inclusive environment ",
          },
        ],
        point: "7.1",
        name: "Institutional Values and Social Responsibilities",
      },

      {
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c7/7.2.pdf",
                name: "Best practices as hosted on the Institutional website		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/7.2.pdf",
                name: "Relevant information		",
              },
            ],
            point: "7.2.1",
            name: "Describe two best practices successfully implemented by the Institution as per NAAC format provided in the Manual",
          },
        ],
        point: "7.2",
        name: "Best Practices",
      },

      {
        secondSub: [
          {
            alphaSub: [
              {
                link: "./assets/documentation/naac/ssr/c7/7.3.pdf",
                name: "Appropriate web in the Institutional website		",
              },
              {
                link: "./assets/documentation/naac/ssr/c7/7.3.pdf",
                name: "Relevant information		",
              },
            ],
            point: "7.3.1",
            name: "Portray the performance of the Institution in one area distinctive to its priority and thrust within 1000 words",
          },
        ],
        point: "7.3",
        name: "Institutional Distinctiveness",
      },
    ],
  },
];
