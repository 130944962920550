export const timetable = [

     { displayName: 'ESE Practical Exam First Year Sem-1 Timetable', link: './assets/documentation/timetable/2023/ESE Practical Exam First Year Sem-1 Timetable.pdf' }
    , { displayName: 'Second Sessional Practical Exam First Year TT', link: './assets/documentation/timetable/2023/Second Sessional Practical Exam First Year TT.pdf' }
    , { displayName: 'End Semester Practical Exam of Third and Final year TT', link: './assets/documentation/timetable/2023/End Semester Practical Exam of Third and Final year TT.pdf' }
    , { displayName: 'Oct 2022 semester Exam Timetable', link: './assets/documentation/timetable/2023/oct 2022 semester Exam Timetable.pdf' }
    , { displayName: 'First Sessional Practical Exam of TY and Final Y TT', link: './assets/documentation/timetable/2023/First Sessional Practical Exam of TY and Final Y TT.pdf' },
    /*  { displayName: 'Revised Time Table Second Sessional Practical Examination 2022-23', link: './assets/documentation/timetable/t1.pdf' }
     , { displayName: 'Revised Time Table Second Sessional Theory Examination 2022-23.pd', link: './assets/documentation/timetable/t2.pdf' }
     , { displayName: 'Second Sessional Practical Practical Examination 2022-23', link: './assets/documentation/timetable/sesPracex22-23.pdf' }
     , { displayName: 'Time Table First Sessional Practical Examination 2022-23', link: './assets/documentation/timetable/Time Table First Sessional Practical Examination 2022-23.pdf' }
     , { displayName: 'Time Table First Sessional Theory Examination 2022-23', link: './assets/documentation/timetable/Time Table First Sessional Theory Examination 2022-23.pdf' } */
];


