import { Component, OnInit } from "@angular/core";
import { ssrConstants } from "./ssr.constant";

@Component({
  selector: "app-ssr",
  templateUrl: "./ssr.component.html",
  styleUrls: ["./ssr.component.css"],
})
export class SsrComponent implements OnInit {
  readonly ssrConst = ssrConstants;
  constructor() {}

  ngOnInit(): void {}
}
