import { Component, OnInit } from '@angular/core';
import { examNotice } from 'src/app/constant/importantLinks.constant';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {

  readonly examNotice = examNotice;
  constructor() { }

  ngOnInit(): void {
  }

}
