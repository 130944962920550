import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { menuItems } from '../../constant/menu.constant';
import { applicationName } from '../../constant/applicationDetails.constant';

@Component({
  selector: 'my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.css']
})
export class MyNavComponent {

  menuList = [];
  readonly applicationName = applicationName;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.menuList = menuItems;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );


}
