import { Component, OnInit } from "@angular/core";
import { URLSanitizerService } from "src/app/component/service/urlsanitizer.service";

@Component({
  selector: "app-institutional-distinctiveness",
  templateUrl: "./institutional-distinctiveness.component.html",
  styleUrls: ["./institutional-distinctiveness.component.css"],
})
export class InstitutionalDistinctivenessComponent implements OnInit {
  constructor(public sanitiseService: URLSanitizerService) {}

  ngOnInit(): void {}
}
