export const researchList = [
  {
    title: "IPR Awareness Webinar",
    link: "./assets/documentation/naac/research/1_IPR Awareness Webinar.pdf",
  },
  {
    title: "Workshop attended on NEP and its Implementation",
    link: "./assets/documentation/naac/research/2_Workshop attended on NEP and its Implementation.pdf",
  },
  {
    title: "Guest Lecture on IPR and  Patent filing",
    link: "./assets/documentation/naac/research/3_Guest Lecture on IPR and  Patent filing.pdf",
  },
  {
    title: "Guest lecture on Laboratory Equipment Management",
    link: "./assets/documentation/naac/research/5_Guest lecture on Laboratory Equipment Management.pdf",
  },
  {
    title: "Guest lecture on Entrepreneurship Management",
    link: "./assets/documentation/naac/research/6_Guest lecture on Entrepreneurship Management.pdf",
  },
  {
    title: "Workshop on Basics of Social Media and Digital Marketing",
    link: "./assets/documentation/naac/research/7_Workshop on Basics of Social Media and Digital Marketing.pdf",
  },
  {
    title: "Guest lecture on Career in Clinical Research",
    link: "./assets/documentation/naac/research/8_Guest lecture on Career in Clinical Research.pdf",
  },
  {
    title: "Guest lecture on Structural determination using spectroscopy",
    link: "./assets/documentation/naac/research/Guest lecture on Structural determination using spectroscopy.pdf",
  },
];


